import { Text } from "@chakra-ui/react"
import { navigate } from "gatsby"
import React, { useContext, useEffect } from "react"
import {
  ApiContext,
  CompanyContext,
  defaultApi,
  defaultCompany,
  defaultUser,
  UserContext,
} from "../components/Root"
import { setAmplitudeUserId } from "../services/amplitude"

function LogoutPage() {
  const user = useContext(UserContext)
  const api = useContext(ApiContext)
  const company = useContext(CompanyContext)
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem("token")
      window.localStorage.removeItem("user")
      window.localStorage.removeItem("company")
    }
    console.log("what is api", api)
    api.setApi(defaultApi.client)
    user.setUser(defaultUser.data)
    company.setCompany(defaultCompany.data)
    navigate("/")
  }, [])

  return <Text>Logging you out...</Text>
}

export default LogoutPage
